
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fundcore-primary: mat.define-palette(mat.$indigo-palette);
$fundcore-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fundcore-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fundcore-theme: mat.define-light-theme((
  color: (
    primary: $fundcore-primary,
    accent: $fundcore-accent,
    warn: $fundcore-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fundcore-theme);

/* You can add global styles to this file, and also import other style files */

*{
  font-family: "Lato", sans-serif;
}
html, body{
  height: 100%;
  --SpacingXS: 8px;
  --SpacingS: 12px;
  --S: 2px;
  --M: 4px;
  --L: 8px;
  --XL: 16px;
  --XXL: 24px;
  --Full: 100px;
  --Foundation-Azul-Principal-50: #E6F5FE;
  --Foundation-Azul-Principal-200: #8ACFF8;
  --Foundation-Azul-Principal-500: #0097F0;
  --Foundation-Azul-Principal-700: #006BAA;
  --Foundation-Azul-Principal-900: #003F65;
  --Foundation-Error-error-50: #FFEEE9;
  --Foundation-Error-error-500: #FF5722;
  --Foundation-Error-error-700: #B53E18;
  --Foundation-Gris-gris-100: #E1E1E1;
  --Foundation-Gris-gris-500: #9E9E9E;
  --Foundation-Gris-gris-700: #707070;
  --Foundation-Azul-Principal-100: #B0DFFA;
  --Foundation-superficie-light-white-active: #D6EEFD;
  --Foundation-superficie-light-white-hover: #F2FAFE;
  --Foundation-Superficie-Dark-Light-Default: #F2F4F6;
  --Foundation-Superficie-Dark-Normal-Active: #A8B4BF;
  --Blanco: #FFF;
  --Negro: #000;
  --Azul-Sec-500: #024;
  --Error-500: #FF5722;
  --Error-600: #E84F1F;
  --Error-700: #B53E18;
  --Error-800: #8C3013;
  --Alerta-700: #B5A72A;
}

body { margin: 0;}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}


.snack-info.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    border-radius: var(--L, 8px);
      border: 1px solid var(--Foundation-Azul-Principal-500);
      background: var(--Foundation-Azul-Principal-50);

    .mat-mdc-button {
      color: var(--Foundation-Azul-Principal-700);
      @extend .cuerpo-regular;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: var(--Foundation-Azul-Principal-700);
    @extend .cuerpo-regular;
  }
}

.snack-error.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    border-radius: var(--L, 8px);
      border: 1px solid var(--Foundation-Error-error-500);
      background: var(--Foundation-Error-error-50);

    .mat-mdc-button {
      color: var(--Foundation-Error-error-700);
      @extend .cuerpo-regular;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: var(--Foundation-Error-error-700);
    @extend .cuerpo-regular;
  }
}

//Margin-Padding
.mb-0{
  margin-bottom: 0;
}
.mb-10{
  margin-bottom: 10px;
}

//grid

//Flex
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

//Justify Axis X
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}
//Align Axis Y
.items-start {
  align-items: start;
}
.items-end {
  align-items: end;
}
.items-center {
  align-items: center;
}

.title {
  position: sticky;
  top: 56px;
  z-index: 3;
  background-color: white;
  padding: 10px 0 10px 0;
  span{
      font-size: 33px;
      font-weight: 700;
      text-align: left;
  }
}

//Input txt
.txt-container {
  position: relative;
}

.txt-container label {
  @extend .cuerpo-regular;
  position: absolute;
  top: -7px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  height: 16px;
  box-sizing: border-box;
}

.disabled-label{
  background: linear-gradient(to bottom, white 0%, white 50%, #E1E1E1 50%, #E1E1E1 100%);
}

.input-txt {
  @extend .parrafo-regular;
  height: 40px;
  width: 100%;
  padding: var(--L) var(--XL);
  border-radius: var(--XL);
  border: 1px solid #707070;
  background: #ffffff;
  color: #707070;
  text-align: left;
  box-sizing: border-box;
}

.input-txt-cargo{
  width: 110px;
  height: 28px;
}
.input-txt-porcentaje-cargo{
  width: 95px;
  height: 28px;
}

.input-txt-porcentaje-dto {
  @extend .input-txt;
  width: 62px;
}

.container-tipo-pago{
  width: 235px;
}

.container-descuento{
  width: 150px;
}

.container-txt-sm {
  width: 140px;
}

.container-txt-base {
  width: 200px;
}

.container-txt-cards-pr {
  width: 150px;
}

.container-txt-lg {
  width: 100%;
}

.textarea-txt {
  @extend .parrafo-regular;
  height: 70px;
  padding: var(--L) var(--XL) var(--L) var(--XL);
  border-radius: var(--XL);
  border: 1px solid #707070;
  background: #ffffff;
  color: #707070;
  gap: 10px;
  resize: none;
}

.textarea-txt-lg{
  width: 90%;
}

//Input Date
.date-container {
  position: relative;
}

.date-container label {
  @extend .cuerpo-regular;
  position: absolute;
  top: -7px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
}

.input-date{
  @extend .parrafo-regular;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: var(--L) var(--XL) var(--L) var(--XL);
  border-radius: var(--XL);
  border: 1px solid #707070;
  background: #ffffff;
  color: #707070;
}

.date-container-sm{
  width: 200px;
}

.date-container-lg{
  width: 100%;
}

//Input check
.check-input{
  @extend .cuerpo-regular;
  height: 22px;
  padding: var(--L) var(--XL) var(--L) var(--XL);
  border-radius: var(--XL);
  border: 1px solid #707070;
  background: #ffffff;
  color: #707070;
  gap: 10px;
  cursor: pointer;
}

.check-input-sm {
  width: 100px;
}

.check-input-base{
  width: 200px;
}

.check-input-lg {
  width: 90%;
}

.check-input-cargos-aplica{
  width: 80%;
}

.check-input-tipo-pago{
  width: 85%;
}

.check-input-incluye-iva{
  width: 80px;
  height: 10px;
}

.check-input:hover, .check-input:hover > 
.check {
  color: var(--Foundation-Azul-Principal-700);
}

.check {
  background-image: url("./assets/radio_button_unchecked.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: #fff;
  width: 18px;
  height: 18px;
  transition: background-image 0.3s ease-in-out;
}
.check:checked {
  background-image: url("./assets/radio_button_checked.svg");
}

.check:checked~.label {
  color: var(--Foundation-Azul-Principal-700);
}

.label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

//Input Select
.select-container {
  position: relative;
}

.select-container label {
  @extend .cuerpo-regular;
  position: absolute;
  top: -7px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  z-index: 3;
}

.input-select {
  @extend .parrafo-regular;
  width: 100%;
  height: 40px;
  padding: var(--L) var(--XL) var(--L) var(--XL);
  border-radius: var(--XL);
  border: 1px solid #707070;
  color: #707070;
  -webkit-appearance: none;
  background: url(./assets/arrow-down.png) no-repeat right #ffffff;
  background-size: 10px 6px;
  appearance: none;
  background-position: right 1rem center;
  box-sizing: border-box;
}

.container-select-sm {
  width: 140px;
}

.container-select-lg{
  width: 100%;
}

.container-select-base {
  width: 200px;
}

.container-select-lineaC-puntoR {
  width: 270px;
}
.container-select-nav {
  width: 200px;
}

.container-select-doc{
  width: 87px;
}

//General
.input-valid,
.input-txt:focus,
.checked,
.input-txt:not(:placeholder-shown),
.textarea-txt-lg:focus,
.textarea-txt-lg:not(:placeholder-shown) {
  color: var(--Foundation-Azul-Principal-700);
}

.input-error {
  border: 1px solid #FF5722;
  background: #FFEEE9;
  color: #FF5722;
}

.error-message {
  @extend .legal-regular;
  color: #FF5722;
  margin-bottom: 8px;
}

//botones
.btn-primary {
  @extend .parrafo-regular;
  width: 200px;
  height: 40px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: var(--Azul-Sec-500);
  border: solid var(--Azul-Sec-500);
  box-shadow: 0px var(--S) 4px 0px #0000001f;
  border-radius: var(--XL);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  box-sizing: border-box;
}

.btn-primary:hover{
  background-color: var(--Foundation-Azul-Principal-700);
  border: solid var(--Foundation-Azul-Principal-700);
  transition: all 0.3s ease-in-out;
}

.btn-primary:active{
  background-color: var(--Foundation-Azul-Principal-900);
    border: solid var(--Foundation-Azul-Principal-900);
    transition: all 0.3s ease-in-out;
}

.btn-primary-sm {
  width: 100px;
}

.btn-primary-base{
  width: 150px;
}

.btn-primary-lg {
  width: 100%;
}

.btn-primary.disabled{
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
  cursor: not-allowed;
}

.input-txt.disabled{
  color: var(--Foundation-Gris-gris-700);
  background-color: var(--Foundation-Gris-gris-100);
}

.input-txt.disabledNoLabel {
  color: var(--Foundation-Gris-gris-500);
  background-color: var(--Foundation-Gris-gris-100);
}

.btn-warning {
  @extend .btn-primary;
  background-color: var(--Error-500);
  border: solid var(--Error-500);
}

.btn-warning:hover{
  background-color: var(--Error-700);
  border: solid var(--Error-700);
  transition: all 0.3s ease-in-out;
}

.btn-warning:active{
  background-color: var(--Error-800);
  border: solid var(--Error-800);
  transition: all 0.3s ease-in-out;
}

.hyperlink{
  @extend .parrafo-regular;
  color: var(--Foundation-Azul-Principal-700);
  text-decoration-line: underline;
  cursor: pointer;
}

.hyperlink-icon {
  color: var(--Foundation-Azul-Principal-700);
  cursor: pointer;
}

zero-state-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
  gap: var(--SpacingXS)
}

.legal-regular{
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.cuerpo-regular {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.cuerpo-medio {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.cuerpo-semibold {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.cuerpo-bold {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.parrafo-regular{
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.parrafo-semibold{
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.parrafo-bold{
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}


.ttl-1-bold{
  font-family: "Lato", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
} 

.ttl-2-bold{
  font-family: "Lato", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.headline-bold{
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
} 

.btn-disabled {
  @extend .btn-primary;
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
}

form.side-dialog-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: auto;
}

// --------------Impresiones-------------

#printable {
  display: none;
}

@media print {    
  @page {
    size: auto;
    /* auto is the initial value */
    margin: 0mm;
    /* this affects the margin in the printer settings */
  }

  html {
    background-color: #ffffff;
    margin: 0px;
    /* this affects the margin on the html before sending to printer */
  }

  #printable, #visible-printable{
    display: block;
  }

  body>*, #hide-from-print {
    display: none;
  }
}

.impresion-recibo {
  width: 75mm;
  font-size: 11px;
  padding: 0mm 6mm 0mm 5mm;
  margin: 0mm 0.5mm;
}

.contenedor-recibo {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

// ----------Tablas------------

.table__container {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  padding: var(--L);
  margin: var(--M) 0 var(--L) 0;
  border-radius: var(--XL);
  gap: 8px;
  box-shadow: 0px var(--S) 4px 0px rgba(0, 0, 0, 0.12);
  background: var(--Foundation-Superficie-Dark-Light-Default);
}

.table__header {
  @extend .parrafo-semibold;
  text-align: left;
  box-sizing: content-box;
  padding: var(--L) var(--XL);
  min-height: 54px;
  align-items: center;
  gap: 10px;
  background: var(--Foundation-Superficie-Dark-Normal-Active);
  border-radius: var(--XL);
}

.table__row {
  @extend .parrafo-regular;
  box-sizing: content-box;
  padding: var(--M) var(--XL);
  border-radius: var(--XL);
  background: var(--Blanco);
}

.table__row-cells {
  align-items: center;
  gap: 10px;
  min-height: 40px;
}

.table__row-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.table__row-panel--expanded {
  max-height: fit-content;
  transition: max-height 1s ease-in-out;
}

.table__cell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__cell--centered {
  justify-self: center;
}

cdk-table {
  @extend .table__container;
}

cdk-header-row {
  @extend .table__header;
}

cdk-row {
  @extend .table__row;
  @extend .table__row-cells;
}

cdk-cell, cdk-header-cell {
  @extend .table__cell;
}

@for $val from 1 through 18 {
  .grid-col-#{$val}{
    display: grid;
    grid-template-columns: repeat($val, 1fr);
  }
}

.text-center {
  text-align: center;
}

.mayus {
  text-transform: uppercase;
}

.form__check-input--size {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
}
